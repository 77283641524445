.App {
  text-align: center;
  font-family: 'Archivo', sans-serif;
  
}
:root{
  --primary:#DA302B !important;
}
.btn-primary {
  color: #fff !important;
  background-color: #DA302B !important;
  border-color: #DA302B !important;
}
.fotoportada{
  height: 200px;
  width: 100%;
  background-image: url("./assest/portada.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
p{
 text-align: justify;
}
.redes-footer{
  background-color:#DA302B ;
  height: 100px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
 
}
.redes-footer--icons :first-child{
 margin-right: 1em;
}
.redes-footer--icons :last-child{
  margin-left: 1em;
}
.foto-tamanio{
  height: 500px;
  width: auto;
}

.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23f00%27%3e%3cpath d=%27M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z%27/%3e%3c/svg%3e") !important;
}

.carousel-control-next-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23f00%27%3e%3cpath d=%27M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e")!important;
}
.link-atom{
  color: black;
  font-weight: bold;
}

.bg-light{
  --bs-light-rgb:230,230,230 !important;
  
}

@media (max-width: 768px) {
  .foto-tamanio{
    height: auto;
    max-width: 100%;
  }
  
}

